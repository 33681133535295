import React, { lazy, Suspense, useEffect } from "react";
// import ReactGA from "react-ga4";
import { Switch, useLocation } from "react-router-dom";
// Layouts
import LayoutDefault from "./layouts/LayoutDefault";
import AppRoute from "./utils/AppRoute";

// redux
import { useSelector } from "react-redux";
import Loader from "./components/elements/Loader";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import { Toaster } from "react-hot-toast";
import { routes } from "./utils/routes";
import ScrollToTop from "./components/sections/ScrollToTop";

const NotFound404 = lazy(() => import("./views/404"));

const App = () => {
  let location = useLocation();
  const pendingRequests = useSelector((store) => store.ui.activeApiTokens);
  const { setPathPageView, setContentType } = useTrackingCode();

  useEffect(() => {
    setContentType("landing-page");
  }, [setContentType]);

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add("is-loaded");
    // trackPage(page);
    setPathPageView(page);
    window.scrollTo({ top: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <Suspense fallback={<Loader />}>
        {pendingRequests.length > 0 && <Loader />}
        <Switch>
          {routes.map(({ isExact, component, layout, path }) => (
            <AppRoute
              key={path}
              path={path}
              exact={isExact}
              component={component}
              layout={layout}
            />
          ))}

          <AppRoute
            exact
            path="/*"
            component={NotFound404}
            layout={LayoutDefault}
          />
        </Switch>
        <ScrollToTop />
        <Toaster />
      </Suspense>
    </>
  );
};

export default App;
