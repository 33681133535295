import PropTypes from "prop-types";
import React from "react";
// import FooterNav from "./partials/FooterNav";
import FooterSocial from "./partials/FooterSocial";
import Logo from "./partials/Logo";
import { Link } from "react-router-dom";

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool,
};

const defaultProps = {
  topOuterDivider: false,
  topDivider: false,
};

const Footer = ({ className, topOuterDivider, topDivider, ...props }) => {
  return (
    <footer {...props} className="footer">
      <div className="container">
        <div className="left">
          <Logo />
          <div className="footer-heading">
            Single platform to make your data useful and actionable for business
            teams
          </div>
          <FooterSocial />
        </div>
        <div className="right">
          {/* <div className="types">
            <div className="type-heading">product</div>
            <ul>
              <a
                href="https://google.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <li>Connectors</li>
              </a>
              <Link to="/connectors">
              <li>Charts</li>
              </Link>
              <li>Comparison</li>
              <li>Documentation</li>
            </ul>
          </div> */}
          <div className="types">
            <div className="type-heading">Solutions</div>
            <ul>
              <Link to="/solutions/product">
                <li>Product</li>
              </Link>
              <Link to="/solutions/marketing">
                <li>Marketing</li>
              </Link>
              <Link to="/solutions/revenue-operations">
                <li>Revenue Ops</li>
              </Link>
              <Link to="/solutions/data-analyst">
                <li>Data Analyst</li>
              </Link>
              <Link to="/solutions/finance">
                <li>Finance</li>
              </Link>
              <Link to="/solutions/operations">
                <li>Operations</li>
              </Link>
            </ul>
          </div>
          <div className="types">
            <div className="type-heading">Resources</div>
            <ul>
              <Link to="/case-studies">
                <li>Case Studies</li>
              </Link>
              <a href="/blog">
                <li>Blog</li>
              </a>
              <a
                href="https://docs.datazip.io/s/home"
                target="_blank"
                rel="noopener noreferrer"
              >
                <li>Documentation</li>
              </a>
              <Link to="/faq">
                <li>Faqs</li>
              </Link>
              {/* <a
                href="https://release-notes.haveignition.com/datazip/board/631840914d23a40014014587"
                target="_blank"
                rel="noopener noreferrer"
              >
                <li>Request a Feature</li>
              </a> */}
            </ul>
          </div>
          <div className="types">
            <div className="type-heading">Company</div>
            <ul>
              <Link to="/about-us">
                <li>About us</li>
              </Link>
              <Link to="/pricing">
                <li>Pricing</li>
              </Link>

              {/* <a
                href="https://datatribe.datazip.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <li>Community</li>
              </a> */}
              {/* <a
                href="https://datazip.zohorecruit.com/jobs/Careers"
                target="_blank"
                rel="noopener noreferrer"
              >
                <li>Careers</li>
              </a> */}
              <Link to="/contact-us">
                <li>Contact us</li>
              </Link>
            </ul>
          </div>
          <div className="types">
            <div className="type-heading">Compare</div>
            <ul>
              <a
                href="https://docs.datazip.io/s/2231ad47-f3e2-4956-a9d1-d38039772b7e/doc/datazip-vs-tableau-ZWqBWLioaD"
                target="_blank"
                rel="noopener noreferrer"
              >
                <li>vs Tableau</li>
              </a>
              <a
                href="https://docs.datazip.io/s/2231ad47-f3e2-4956-a9d1-d38039772b7e/doc/datazip-vs-fivetran-5BIFVTNfrF"
                target="_blank"
                rel="noopener noreferrer"
              >
                <li>vs Fivetran</li>
              </a>
              <a
                href="https://docs.datazip.io/s/2231ad47-f3e2-4956-a9d1-d38039772b7e/doc/datazip-vs-snowflake-x641LSdgzJ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <li>vs Snowflake</li>
              </a>
              <a
                href="https://docs.datazip.io/s/2231ad47-f3e2-4956-a9d1-d38039772b7e/doc/datazip-vs-segment-mzgLkC9xUt"
                target="_blank"
                rel="noopener noreferrer"
              >
                <li>vs Segment</li>
              </a>
              <a
                href="https://docs.datazip.io/s/2231ad47-f3e2-4956-a9d1-d38039772b7e"
                target="_blank"
                rel="noopener noreferrer"
              >
                <li>Compare other tools</li>
              </a>
            </ul>
          </div>
          <div className="types">
            <div className="type-heading">Legal</div>
            <ul>
              <Link to="/terms-of-use">
                <li>Terms of Use</li>
              </Link>
              <Link to="/privacy-policy">
                <li>Privacy (Visitors)</li>
              </Link>
              <Link to="/privacy-policy-customer">
                <li>Privacy (Customers)</li>
              </Link>
              <Link to="/cancellations-refunds">
                <li>Cancellations and Refunds</li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
      <div className="text__sub">© 2024 Datazip. All rights reserved</div>
      <div className="company-address">
        Datazip, Inc. 16192 COASTAL HWY LEWES, DE 19958, USA
      </div>
    </footer>
  );
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
